.er_events {
  position: relative;
  width: 100%;
  background-image: linear-gradient(0deg, #151836, #151836),
    radial-gradient(126.13% 100% at 50% 0%, #644bff 0%, #0720f7 100%);
  padding: 80px 0 100px;

  @include respond-to(tablet) {
    padding: 60px 0 80px;
  }

  @include respond-to(phone) {
    padding: 40px 0 60px;
  }

  &::before {
    content: '';
    @include background-image('events/events');
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-size: cover;
    background-attachment: fixed;
  }

  &__inner {
    position: relative;
    z-index: 2;

    &__title {
      @include h2;
      color: $white-100;
      margin-bottom: 80px;

      @include respond-to(tablet) {
        margin-bottom: 60px;
      }

      @include respond-to(phone) {
        margin-bottom: 40px;
      }
    }
  }

  &__slider {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    @include respond-to(tablet) {
      align-items: center;
    }

    &_container {
      width: 100%;

      @include respond-to(mini) {
        padding: 0 24px;
      }
    }

    &__wrapper {
      &__slide {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        max-width: 360px;
        min-height: 350px;
        background-color: $white-10;
        backdrop-filter: blur(20px);
        border-radius: 30px;
        padding-left: 40px;
        padding-right: 40px;
        transition: padding-top 0.25s ease-out;

        @include respond-to(tablet) {
          width: 360px;
          justify-content: center;
          padding-bottom: 20px;
        }

        @include respond-to(phone) {
          width: 300px;
          min-height: 300px;
        }

        @include respond-to(mini) {
          width: 100%;
          min-width: 100%;
        }

        &__text {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          transition: transform 0.25s ease-out;
        }

        &__title {
          position: relative;
          font-weight: 700;
          font-size: 28px;
          line-height: 34px;
          text-align: center;
          color: $white-100;

          @include respond-to(tablet) {
            font-size: 24px;
            line-height: 30px;
          }

          @include respond-to(phone) {
            font-size: 20px;
            line-height: 24px;
          }
        }

        &__desc {
          font-size: 22px;
          line-height: 26px;
          text-align: center;
          color: $white-60;
          margin-top: 10px;

          @include respond-to(tablet) {
            font-size: 20px;
            line-height: 30px;
            margin-top: 8px;
          }

          @include respond-to(phone) {
            font-size: 18px;
            line-height: 28px;
            margin-top: 6px;
          }
        }

        &__button {
          display: block;
          @include main-button;
          opacity: 0;
          transition: opacity 0.25s ease-out;
          margin-top: 40px;

          @include respond-to(tablet) {
            margin-top: 30px;
            opacity: 1;
          }

          @include respond-to(phone) {
            margin-top: 20px;
          }
        }

        &:hover {
          transition: padding-top 0.25s ease-in;

          .er_events__slider__wrapper__slide__button {
            opacity: 1;
            transition: opacity 0.25s ease-in;
          }
        }
      }
    }

    &__buttons {
      margin: 60px 220px 0;
      display: flex;
      position: relative;
      z-index: 10;
      cursor: pointer;

      @include respond-to(tablet) {
        margin: 40px 40px 0;
      }

      &__item {
        background-color: $white-10;
        border-radius: 40px;
        width: 72px;
        height: 72px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $white-100;

        svg {
          transition: fill-opacity 0.25s linear;
        }

        &:hover {
          svg {
            fill-opacity: 0.6;
          }
        }

        &.swiper-button-disabled {
          color: $pagination;
          svg {
            fill-opacity: 0.3;
          }
        }

        &:not(:last-child) {
          margin-right: 32px;
        }
      }
    }
  }
}
