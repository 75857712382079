/* Timings */

$length-of-ticker-animation: 60s;
$length-of-text-change-animation: 0.4s;

/* Animations */

@keyframes ticker {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

@-webkit-keyframes ticker {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }
}

@-moz-keyframes ticker {
  0% {
    -moz-transform: translate(0);
    transform: translateX(0);
  }
  100% {
    -moz-transform: translateX(-50%);
    transform: translateX(-50%);
  }
}