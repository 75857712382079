.er_schedule {
  margin-top: 120px;
  margin-bottom: 60px;

  @include respond-to(tablet) {
    margin-top: 80px;
    margin-bottom: 40px;
  }

  @include respond-to(phone) {
    margin-top: 60px;
    margin-bottom: 30px;
  }

  &__inner {
    &__title {
      @include h2;
      margin-bottom: 20px;
    }

    &__content {
      display: flex;

      @include respond-to(tablet) {
        display: none;
      }

      &__text {
        padding-top: 140px;
        padding-bottom: 500px;
        margin-right: 40px;

        @include respond-to(tablet-large) {
          margin-right: 0;
        }

        &__item {
          display: flex;
          color: $light-grey;
          cursor: pointer;

          &_active {
            color: $black;
          }

          &:not(:last-child) {
            margin-bottom: 80px;
          }

          &__icon {
            display: flex;
            margin-right: 36px;
            position: relative;
            top: -5px;
          }

          &__title {
            max-width: 480px;
            width: 100%;
            font-weight: 600;
            font-size: 26px;
            line-height: 36px;
          }
        }
      }

      &__figure {
        display: flex;
        justify-content: center;
        justify-self: center;
        width: 560px;
        height: 765px;
        position: sticky;
        align-self: flex-start;
        top: 0;
        padding-top: 85px;

        &__picture {
          picture {
            position: absolute;
            left: 0;
            width: 100%;
            height: 100%;

            img {
              opacity: 0;
              transition: opacity 0.6s ease-out;
              object-fit: contain;
            }
          }

          &_active {
            picture {
              img {
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }
}
