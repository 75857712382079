.modal-wrapper {
  &.privacy-modal {
    .modal {
      &-window {
        transition: transform 0.4s ease;
        transform: scale(0.8);
        width: 65%;
        max-width: 920px;
        height: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        border-radius: 30px;
        background: $white-100;
        overflow: hidden;
        -webkit-overflow-scrolling: touch;
        max-height: 92%;

        @include respond-to(tablet) {
          width: 80%;
        }

        @include respond-to(phone) {
          width: 92%;
        }
      }
      &-header {
        z-index: 10;
        .modal-close {
          position: absolute;
          cursor: pointer;
          margin-right: 16px;
          margin-top: 16px;
          top: 0;
          right: 0;
          color: $grey;
          transition: color 0.15s ease-in-out;
          width: 40px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          &:hover {
            color: $light-grey;
          }

          @include respond-to(tablet) {
            margin-right: 10px;
            margin-top: 10px;
          }
        }
      }
      &-container {
        width: 100%;
        overflow: auto;
        text-align: left;
        padding: 56px 48px;
        background: transparent;
        position: relative;
        transform: translate(-50%, -50%);
        opacity: 1;
        transition: none;
        max-height: unset;

        @include respond-to(tablet) {
          padding: 56px 32px;
        }

        @include respond-to(phone) {
          padding: 56px 24px;
        }

        ul {
          & > li {
            list-style: outside;
            margin-left: 18px;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;

            @include respond-to(phone) {
              font-size: 14px;
              line-height: 22px;
            }
          }
        }
        ol {
          margin: 16px 0;
          padding-left: 40px;

          @include respond-to(tablet) {
            padding-left: 30px;
            margin: 12px 0;
          }

          @include respond-to(phone) {
            padding-left: 20px;
            margin: 8px 0;
          }

          & > li {
            margin-bottom: 20px;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;

            @include respond-to(phone) {
              font-size: 14px;
              line-height: 22px;
            }
          }
        }
        h2 {
          font-weight: 800;
          font-size: 32px;
          line-height: 40px;
          margin-bottom: 20px;
          text-align: center;

          @include respond-to(tablet) {
            font-size: 24px;
            line-height: 30px;
            margin-bottom: 16px;
          }

          @include respond-to(phone) {
            font-size: 20px;
            line-height: 25px;
          }
        }
        a {
          color: $pink;
          transition: color 0.25s linear;

          &:hover {
            color: $pink-hover;
          }
        }
      }
    }
  }
}
