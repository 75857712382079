.er_diagrams {
  margin-bottom: 120px;

  @include respond-to(tablet) {
    margin-bottom: 80px;
  }

  @include respond-to(phone) {
    margin-bottom: 60px;
  }

  &__inner {
    &__title {
      @include h2;
      margin-bottom: 80px;

      @include respond-to(tablet) {
        margin-bottom: 60px;
      }

      @include respond-to(phone) {
        margin-bottom: 40px;
      }
    }

    &__block {
      &__item {
        background-color: $white-grey;
        padding: 60px 100px 84px;
        border-radius: 48px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include respond-to(tablet) {
          padding: 40px 66px 56px;
        }

        @include respond-to(phone) {
          padding: 30px 32px 42px;
          flex-direction: column;
        }

        @include respond-to(mini) {
          padding: 24px 26px 36px;
        }

        &:not(:last-child) {
          margin-bottom: 60px;

          @include respond-to(tablet) {
            margin-bottom: 40px;
          }

          @include respond-to(phone) {
            margin-bottom: 30px;
          }
        }

        & > div:first-child {
          margin-right: 60px;

          @include respond-to(tablet) {
            margin-right: 40px;
          }

          @include respond-to(phone) {
            margin-right: 0;
            margin-bottom: 30px;
          }
        }

        &__info {
          &__title {
            font-weight: 800;
            font-size: 32px;
            line-height: 40px;
            color: $black;
            margin-bottom: 32px;
            width: 100%;
            max-width: 560px;

            @include respond-to(tablet) {
              font-size: 24px;
              line-height: 30px;
              margin-bottom: 20px;
            }

            @include respond-to(phone) {
              font-size: 20px;
              line-height: 25px;
              margin-bottom: 16px;
            }
          }

          &__list {
            font-weight: 500;
            font-size: 18px;
            line-height: 24px;
            color: $grey;
            width: 100%;
            max-width: 428px;

            @include respond-to(tablet) {
              font-size: 16px;
              line-height: 22px;
            }

            @include respond-to(phone) {
              font-size: 14px;
              line-height: 20px;
            }

            li {
              list-style-type: none;
              display: flex;

              &::before {
                content: '';
                background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.0116 4.02547C16.4022 3.63494 17.0353 3.63494 17.4259 4.02547C17.8164 4.41599 17.8164 5.04916 17.4259 5.43968L8.94058 13.925C8.55005 14.3155 7.91689 14.3155 7.52636 13.925C7.13584 13.5344 7.13584 12.9013 7.52636 12.5107L16.0116 4.02547Z' fill='%23B4B8C1'/%3E%3Cpath d='M3.57429 9.97469C3.18377 9.58416 3.18377 8.951 3.57429 8.56047C3.96482 8.16995 4.59798 8.16995 4.98851 8.56047L8.93826 12.5102C9.32878 12.9007 9.32878 13.5339 8.93826 13.9244C8.54773 14.315 7.91457 14.315 7.52404 13.9244L3.57429 9.97469Z' fill='%23B4B8C1'/%3E%3C/svg%3E%0A");
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
                flex-shrink: 0;
                display: inline-flex;
                margin-right: 12px;
                position: relative;
                top: 3px;
                width: 20px;
                height: 20px;

                @include respond-to(tablet) {
                  width: 16px;
                  height: 16px;
                  margin-right: 10px;
                }

                @include respond-to(phone) {
                  width: 14px;
                  height: 14px;
                  margin-right: 6px;
                  top: 4px;
                }
              }

              &:not(:last-child) {
                margin-bottom: 10px;

                @include respond-to(tablet) {
                  margin-bottom: 8px;
                }

                @include respond-to(phone) {
                  margin-bottom: 6px;
                }
              }
            }
          }

          &__sign {
            margin-top: 32px;
            margin-left: 32px;
            display: block;
            font-weight: 600;
            font-size: 18px;
            line-height: 24px;
            color: $pink;
            transition: color 0.25s linear;

            &:hover {
              color: $pink-hover;
            }
          }
        }

        &__diagram {
          position: relative;
          width: 340px;
          height: 340px;
          flex-shrink: 0;

          @include respond-to(tablet) {
            width: 300px;
            height: 300px;
          }

          @include respond-to(phone) {
            width: 260px;
            height: 260px;
          }

          @include respond-to(mini) {
            width: 230px;
            height: 230px;
          }

          svg {
            width: 100%;
            height: 100%;
            transform: scale(-1, 1);

            &.invisible:not(.animate) {
              opacity: 0;
            }
          }

          &__text {
            position: absolute;
            margin: auto;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 3;
            width: 100%;
            max-width: 64%;
            height: 74%;
            padding: 50px 0;
            display: flex;
            flex-direction: column;
            justify-content: center;

            &__percent {
              font-weight: 800;
              font-size: 52px;
              line-height: 60px;
              color: $black;
              margin-bottom: 8px;
              text-align: center;

              @include respond-to(tablet) {
                font-size: 40px;
                line-height: 46px;
              }

              @include respond-to(phone) {
                font-size: 32px;
                line-height: 36px;
              }
            }

            &__info {
              font-weight: 500;
              font-size: 14px;
              line-height: 20px;
              color: $grey;
              text-align: center;

              @include respond-to(tablet) {
                font-size: 12px;
                line-height: 18px;
              }

              @include respond-to(phone) {
                font-size: 10px;
                line-height: 14px;
              }
            }
          }
        }
      }
    }
  }
}
