$animate-distance: 100px !default;
$transition: 550ms ease-out;

@media only screen and (min-width: 1024px) {
  [data-animate] {
    @for $i from 1 through 60 {
      body[data-animate-delay='#{$i * 50}'] &,
      &[data-animate][data-animate-delay='#{$i * 50}'] {
        transition-delay: 0s;
  
        &.animate-completed {
          transition-delay: #{$i * 50}ms;
        }
      }
    }
  }
  
  [data-animate] {
    pointer-events: none;
    &.animate-completed {
      pointer-events: auto;
    }
  }
  
  [data-animate^='fade'][data-animate^='fade'] {
    opacity: 0;
    transition-property: opacity, transform;
    transition: $transition;
  
    &.animate-completed {
      opacity: 1;
      transform: none;
    }
  }
  
  [data-animate='fade-up'] {
    transform: translate3d(0, $animate-distance, 0);
  }
  
  [data-animate='fade-down'] {
    transform: translate3d(0, -$animate-distance, 0);
  }
  
  [data-animate='fade-right'] {
    transform: translate3d(-$animate-distance, 0, 0);
  }
  
  [data-animate='fade-left'] {
    transform: translate3d($animate-distance, 0, 0);
  }
  
  [data-animate='fade-up-right'] {
    transform: translate3d(-$animate-distance, $animate-distance, 0);
  }
  
  [data-animate='fade-up-left'] {
    transform: translate3d($animate-distance, $animate-distance, 0);
  }
  
  [data-animate='fade-down-right'] {
    transform: translate3d(-$animate-distance, -$animate-distance, 0);
  }
  
  [data-animate='fade-down-left'] {
    transform: translate3d($animate-distance, -$animate-distance, 0);
  }
}  
