.er_cookie {
  display: none;
  position: fixed;
  max-width: 760px;
  border-radius: 16px;
  box-shadow: 0 2px 8px 0 $shadow;
  border: 1px solid $border;
  background-color: $white-100;
  left: 20px;
  bottom: 40px;
  right: 20px;
  margin-left: auto;
  margin-right: auto;
  z-index: 500;
  padding: 24px 32px;
  align-items: center;

  @include respond-to(tablet) {
    padding: 16px 22px;
  }

  @include respond-to(phone) {
    padding: 12px 16px;
  }

  @include respond-to(mini) {
    flex-direction: column;
  }

  &__text {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: $black;

    @include respond-to(mini) {
      text-align: center;
    }

    a {
      color: $pink;
      transition: color 0.25s linear;
      font-weight: 600;

      &:hover {
        color: $pink-hover;
      }
    }
  }

  &__button {
    margin-left: 24px;
    @include main-button;
    min-width: 140px;

    @include respond-to(mini) {
      margin-left: 0;
      margin-top: 20px;
    }
  }
}
