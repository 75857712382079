.branding_section {
  @include respond-to(desktop-wide) {
    margin: 80px 0 120px;
  }
  @include respond-to(phone-small) {
    padding-bottom: 40px;
  }

  &__inner {
    max-width: 1160px;
    margin-left: auto;
    padding-left: 20px;
    padding-right: 20px;
    margin-right: auto;

    @include respond-to(desktop-wide) {
      max-width: 1160px;
    }

    @include respond-to(tablet-large) {
      max-width: 1080px;
    }

    @include respond-to(tablet) {
      max-width: 904px;
    }

    @include respond-to(tablet-mini) {
      max-width: 812px;
    }

    @include respond-to(phone-extra) {
      max-width: 722px;
    }

    @include respond-to(phone) {
      max-width: 708px;
    }

    @include respond-to(phone-small) {
      max-width: 540px;
    }

    @include respond-to(phone-mini) {
      max-width: 420px;
    }

    @include respond-to(mini) {
      max-width: 385px;
      min-width: 320px;
    }

    &__card {
      display: flex;
      position: relative;
      padding: 60px 0 60px 60px;
      align-items: center;
      height: 480px;
      background: url(/assets/branding/bg-elements.png) no-repeat right bottom/contain,
      radial-gradient(100% 601.44% at 0% 3.59%, #0720f7 0%, #644bff 100%);
      border: 1px solid #e0e0e0;
      border-radius: 30px;

      @include respond-to(tablet) {
        height: 360px;
      }

      @include respond-to(phone-small) {
        height: auto;
        padding: 30px;
        flex-wrap: wrap-reverse;
        text-align: center;
      }

      &__content {
        flex: 1 1 50%;
        margin-right: 40px;

        @include respond-to(phone-small) {
          flex: 1 1 100%;
          margin-right: 0;
          width: 100%;
        }

        &__title {
          max-width: 500px;
          font-weight: 800;
          font-size: 32px;
          line-height: 40px;
          color: white;
          margin-bottom: 16px;

          @include respond-to(mini) {
            font-size: 24px;
            line-height: 28px;
          }
        }

        &__text {
          max-width: 500px;
          color: $white-60;
        }

        &__buttons {
          margin-top: 48px;
          display: flex;
          gap: 24px;

          @include respond-to(tablet-mini) {
            margin-top: 28px;
          }

          @include respond-to(phone-small) {
            justify-content: center;
          }

          &__circle {
            display: inline-block;
            width: 24px;
            height: 24px;
            border: 2px solid $white-100;
            box-sizing: border-box;
            border-radius: 50%;
            transition: linear 250ms;
            transform: scale(1);

            &::before {
              content: '';
              display: block;
              width: 100%;
              height: 100%;
              background-image: url(/assets/branding/ic24_check.svg);
              background-repeat: no-repeat;
              background-size: 70%;
              background-position: center;
              opacity: 0;
              transition: linear 250ms;
            }

            &_blue {
              background: linear-gradient(317.49deg, #051755 15.84%, #03ffff 84.42%);
            }

            &_purple {
              background: linear-gradient(155.38deg, #350059 9.17%, #ff01c4 92.14%);
            }

            &_green {
              background: linear-gradient(149.18deg, #019f23 14.56%, #00220a 81.79%);
            }

            &_orange {
              background: linear-gradient(107.31deg, #ff912b 0%, #e70000 99.29%);
            }

            &.active {
              transform: scale(1.5);

              &::before {
                opacity: 1;
              }
            }

            &:hover {
              transform: scale(1.5);
            }
          }
        }
      }

      &__img {
        flex: 1 1 50%;
        display: flex;
        justify-content: center;
        overflow: hidden;

        @include respond-to(phone-small) {
          margin-bottom: 20px;
        }

        &__container {
          position: relative;
          width: 75%;
          height: 590px;

          @include respond-to(tablet) {
            height: 460px;
          }

          @include respond-to(tablet-mini) {
            height: 400px;
          }

          @include respond-to(phone-extra) {
            height: 340px;
          }

          @include respond-to(phone-small) {
            width: 60%;
            height: 350px;
            transform: translateY(-30%);
            margin-bottom: -20%;
          }

          @include respond-to(mini) {
            width: 65%;
            height: 300px;
            transform: translate(-5%, -30%);
            margin-bottom: -30%;
            object-fit: contain;
          }

          @include respond-to(micro) {
            width: 67%;
            height: 280px;
            transform: translate(-6%, -30%);
            margin-bottom: -33%;
          }

          &__mock {
            @include mockup-no-shadow();
            // filter отключен из-за некорректного отображения в safari
            filter: none;
          }

          &__screen {
            @include screen-in-mockup();

            &_laptop {
              width: 636px;
              max-width: initial;
              left: -70px;
              top: 0;
              bottom: 0;
              margin: auto;

              @include respond-to(tablet-large) {
                width: 636px;
                left: -70px;
              }

              @include respond-to(tablet) {
                width: 500px;
                left: -50px;
              }

              @include respond-to(tablet-mini) {
                width: 455px;
                right: -44px;
              }

              @include respond-to(phone-extra) {
                width: 400px;
                right: -39px;
              }

              @include respond-to(phone-small) {
                width: 165%;
                right: 0;
                left: 0;
                transform: translate(-15%, 25%);
              }
            }
          }
        }
      }
    }
  }
}
