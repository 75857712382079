.blog_section {
  margin-bottom: 160px;

  @include respond-to(tablet) {
    margin-bottom: 120px;
  }

  @include respond-to(phone) {
    margin-bottom: 80px;
  }

  h2 {
    @include h2;
    margin-bottom: 56px;

    @include respond-to(tablet) {
      margin-bottom: 42px;
    }

    @include respond-to(phone) {
      margin-bottom: 30px;
    }
  }

  &__inner {
    display: flex;
    gap: 40px;

    @include respond-to(tablet) {
      flex-direction: column;
      align-items: center;
    }

    &__item {
      flex: 1;
      background-color: $white-grey;
      border-radius: 16px;
      overflow: hidden;
      max-width: 346px;

      @include respond-to(tablet) {
        max-width: 360px;
        width: 100%;
      }

      &__img {
        width: 100%;
        height: 156px;
        position: relative;

        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: $black;
          z-index: 2;
          opacity: 0;
          transition: opacity 600ms ease;
        }

        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }

      &:hover {
        .blog_section__inner__item__img::after {
          opacity: 0.15;
        }
      }

      &__content {
        padding: 24px 24px 36px;

        span {
          display: block;
          font-weight: 800;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.8px;
          text-transform: uppercase;
          color: $blue;
          margin-bottom: 12px;
        }

        h3 {
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 24px;
          color: $black;
        }
      }
    }
  }
}
