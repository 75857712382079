.er_platform {
  position: relative;
  background-color: $background-black;
  padding: 56px 0 60px;
  margin-bottom: 120px;

  @include respond-to(tablet) {
    padding: 42px 0 40px;
    margin-bottom: 80px;
  }

  @include respond-to(phone) {
    padding: 32px 0 30px;
    margin-bottom: 60px;
  }

  &__image {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    z-index: 1;
    opacity: 0.3;

    @include respond-to(phone) {
      background-size: cover;
    }

    &_left {
      left: 0;
      @include background-image('platform-function/05-BG-left');
      background-position: left center;

      @include respond-to(tablet-large) {
        transform: translateX(-16%);
      }

      @include respond-to(tablet) {
        transform: translateX(-20%);
      }

      @include respond-to(phone) {
        background-position: right center;
        transform: translateX(-30%);
      }

      @include respond-to(mini) {
        transform: translateX(-10%);
      }
    }

    &_right {
      right: 0;
      @include background-image('platform-function/05-BG-right');
      background-position: right center;

      @include respond-to(tablet-large) {
        transform: translateX(16%);
      }

      @include respond-to(tablet) {
        transform: translateX(20%);
      }

      @include respond-to(phone) {
        background-position: left center;
        transform: translateX(30%);
      }

      @include respond-to(mini) {
        transform: translateX(10%);
      }
    }
  }

  &__inner {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__title {
      font-weight: 700;
      font-size: 28px;
      line-height: 34px;
      text-align: center;
      color: $white-100;
      margin-bottom: 28px;

      @include respond-to(tablet) {
        font-size: 22px;
        line-height: 26px;
        margin-bottom: 20px;
      }

      @include respond-to(tablet) {
        font-size: 20px;
        line-height: 24px;
      }
    }

    &__button {
      @include main-button;
      margin: 0 auto;
    }
  }
}
