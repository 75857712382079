@mixin h2 {
  font-weight: 800;
  font-size: 44px;
  line-height: 52px;
  text-align: center;
  color: $black;
  margin-left: auto;
  margin-right: auto;
  max-width: 760px;
  width: 100%;

  @include respond-to(tablet) {
    font-size: 32px;
    line-height: 38px;
  }

  @include respond-to(phone) {
    font-size: 24px;
    line-height: 28px;
  }
}

@mixin h2_desc {
  font-weight: 500;
  font-size: 22px;
  line-height: 32px;
  text-align: center;
  color: $grey;
  margin-left: auto;
  margin-right: auto;
  max-width: 760px;
  width: 100%;

  @include respond-to(tablet) {
    font-size: 20px;
    line-height: 30px;
  }

  @include respond-to(phone) {
    font-size: 18px;
    line-height: 28px;
  }
}
