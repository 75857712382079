.modal-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: transparent;
  display: flex;
  visibility: hidden;
  z-index: 1000;
  top: 0;
  transition: background-color 0.4s ease;

  &:target {
    display: flex;
    justify-content: center;
    align-items: center;
    visibility: visible;
    background: $black-60;

    &.privacy-modal {
      .modal-window {
        transform: scale(1);
      }
    }
  }

  &.form-modal {
    .modal-container {
      width: 70%;
      max-width: 800px;

      @include respond-to(tablet) {
        width: 80%;
      }

      @include respond-to(phone) {
        width: 92%;
      }
    }

    .modal-close {
      position: absolute;
      cursor: pointer;
      margin-right: 16px;
      margin-top: 16px;
      top: 0;
      right: 0;
      color: $grey;
      transition: color 0.15s ease-in-out;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;

      @include respond-to(tablet) {
        margin-right: 10px;
        margin-top: 10px;
      }

      &:hover {
        color: $light-grey;
      }
    }
  }
  
  &.error-modal {
    .modal-container {
      width: 70%;
      max-width: 480px;

      @include respond-to(tablet) {
        width: 80%;
      }

      @include respond-to(phone) {
        width: 92%;
      }
    }

    .er_modal {
      &__title {
        margin-bottom: 20px;
        font-weight: 800;
        font-size: 24px;
        line-height: 1.25;
  
        @include respond-to(tablet) {
          font-size: 20px;
          line-height: 18px;
          margin-bottom: 16px;
        }
  
        @include respond-to(phone) {
          font-size: 18px;
          line-height: 20px;
        }
      }
  
      &__text {
        margin-bottom: 32px;
      }
  
      &__button_container {
        button {
          @include main-button;
        }
      }
    }
  }

  .modal-container {
    text-align: center;
    width: 100%;
    position: fixed;
    padding: 56px 48px;
    background: $white-100;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -35%) scale(0.85);
    opacity: 0;
    transition: 0.4s ease;
    max-height: 92%;
    overflow-y: auto;
    overflow-x: hidden;

    @include respond-to(tablet) {
      padding: 56px 32px;
    }

    @include respond-to(phone) {
      padding: 56px 24px;
    }
  }

  &.visible {
    .modal-container {
      transform: translate(-50%, -50%) scale(1);
      opacity: 1;
    }
  }
}
