.er_real_ideas {
  margin-bottom: 120px;

  @include respond-to(tablet) {
    margin-bottom: 80px;
  }

  @include respond-to(phone) {
    margin-bottom: 60px;
  }

  &__inner {
    &__title {
      @include h2;
      margin-bottom: 30px;

      @include respond-to(tablet) {
        margin-bottom: 20px;
      }
    }

    &__buttons {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      margin-bottom: 60px;
      padding-top: 30px;
      max-width: 966px;
      margin-left: auto;
      margin-right: auto;

      @include respond-to(tablet) {
        padding-top: 20px;
        margin-bottom: 40px;
      }

      @include respond-to(phone) {
        margin-bottom: 30px;
      }

      &__button {
        @include grey-button;
        font-weight: 500;
        margin-bottom: 10px;

        &:not(:last-child) {
          margin-right: 8px;
        }
      }
    }

    &__images {
      position: relative;
      width: 100%;

      &__image {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.25s linear;

        &_active {
          opacity: 1;
          visibility: visible;
          position: relative;
        }
      }
    }
  }
}
