.er_developed {
  margin-bottom: 160px;

  @include respond-to(tablet) {
    margin-bottom: 120px;
  }

  @include respond-to(phone) {
    margin-bottom: 80px;
  }

  &__inner {
    &__title {
      @include h2;
      margin-bottom: 56px;

      @include respond-to(tablet) {
        margin-bottom: 42px;
      }

      @include respond-to(phone) {
        margin-bottom: 30px;
      }
    }
    &__block {
      display: flex;

      @include respond-to(phone) {
        flex-direction: column;
        align-items: center;
      }

      &__item {
        display: flex;
        flex-direction: column;
        align-items: center;

        @include respond-to(phone) {
          width: 100%;
          max-width: 270px;
        }

        &:not(:last-child) {
          margin-right: 40px;

          @include respond-to(tablet) {
            margin-right: 30px;
          }

          @include respond-to(phone) {
            margin-right: 0;
            margin-bottom: 30px;
          }
        }

        &__img {
          margin-bottom: 16px;

          @include respond-to(tablet) {
            margin-bottom: 10px;
          }

          img {
            @include respond-to(phone) {
              width: 100px;
            }
          }
        }

        &__text {
          font-weight: 500;
          font-size: 16px;
          line-height: 22px;
          text-align: center;
          color: $black;
        }
      }
    }
  }
}
