.er_associations {
  margin: 160px 0;
  user-select: none;
  position: relative;

  @include respond-to(tablet) {
    margin: 100px 0;
  }

  @include respond-to(phone) {
    margin: 80px 0;
  }

  &__inner {
    background-color: $white-grey;
    padding: 60px 20px 80px;
    border-radius: 48px;

    @include respond-to(tablet) {
      padding: 40px 20px 60px;
      border-radius: 40px;
    }

    @include respond-to(phone) {
      padding: 30px 20px 40px;
      border-radius: 36px;
    }

    &__desktop {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      gap: 28px;
      max-width: 720px;
      margin: 0 auto;
      @include respond-to(phone) {
        display: none;
      }
    }

    &__title {
      font-weight: 800;
      font-size: 32px;
      line-height: 40px;
      text-align: center;
      color: $black;
      margin-bottom: 60px;

      @include respond-to(tablet) {
        font-size: 24px;
        line-height: 30px;
        margin-bottom: 40px;
      }

      @include respond-to(phone) {
        font-size: 20px;
        line-height: 25px;
        margin-bottom: 30px;
      }
    }

    &__slider {
      display: none;

      &-block {
        position: relative;
      }

      @include respond-to(phone) {
        display: block;
      }

      &__item {
        width: 100%;

        img {
          margin: auto;
        }
      }
    }
  }

  &__pagination {
    @include pagination;
    display: none;
    @include respond-to(phone) {
      display: block;
    }
  }

  &__arrows {
    &__prev,
    &__next {
      position: absolute;
      top: 50%;
      cursor: pointer;
      color: $black;
      z-index: 10;
      justify-content: center;
      align-items: center;
      width: 36px;
      height: 36px;
      margin-top: -18px;
      transition: opacity 0.25s linear;
      display: none;

      @include respond-to(phone) {
        display: flex;
      }

      svg {
        width: 36px;
        height: 36px;
      }

      &:hover {
        opacity: 0.6;
      }

      &.swiper-button-disabled {
        color: $pagination;
        cursor: auto;
        pointer-events: none;
      }
    }

    &__prev {
      left: 8px;
    }

    &__next {
      transform: rotate(180deg);
      right: 8px;
    }
  }
}
