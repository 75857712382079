.er_stats {
  margin: 120px 0;

  @include respond-to(tablet) {
    margin: 80px 0;
  }

  @include respond-to(phone) {
    margin: 60px 0;
  }

  &__inner {
    display: flex;
    justify-content: space-between;

    @include respond-to(tablet) {
      flex-wrap: wrap;
    }

    @include respond-to(mini) {
      flex-direction: column;
      align-items: center;
    }

    &__item {
      width: calc(100% / 4);

      @include respond-to(tablet) {
        width: calc(100% / 2);
      }

      &:not(:last-child) {
        margin-right: 40px;

        @include respond-to(tablet) {
          margin-right: 0;
        }

        @include respond-to(mini) {
          margin-bottom: 20px;
        }
      }

      &:nth-child(1),
      &:nth-child(2) {
        @include respond-to(tablet) {
          margin-bottom: 40px;
        }

        @include respond-to(phone) {
          margin-bottom: 20px;
        }
      }

      &__title {
        font-weight: 800;
        font-size: 72px;
        line-height: 80px;
        color: $blue;
        text-align: center;
        margin-bottom: 12px;

        @include respond-to(tablet) {
          font-size: 60px;
          line-height: 66px;
          margin-bottom: 8px;
        }

        @include respond-to(phone) {
          font-size: 40px;
          line-height: 44px;
          margin-bottom: 8px;
        }
      }

      &__text {
        font-weight: 500;
        font-size: 22px;
        line-height: 28px;
        text-align: center;
        color: $black;

        @include respond-to(tablet) {
          font-size: 20px;
          line-height: 26px;
        }

        @include respond-to(phone) {
          font-size: 18px;
          line-height: 24px;
          margin-bottom: 6px;
        }
      }
    }
  }
}
