.subscribe_news{
  padding: 0 0 120px;

  @include respond-to(tablet-mini){
    padding: 0 0 80px;
  }

  @include respond-to(phone){
    padding: 0 0 60px;
  }

  &__inner {
    display: flex;
    align-items: center;

    @include respond-to(tablet-mini){
      flex-direction: column;
    }

    &__title {
      font-weight: 800;
      font-size: 44px;
      line-height: 52px;
      color: $black;
      margin-right: 60px;

      @include respond-to(tablet-mini){
        font-size: 32px;
        line-height: 38px;
        margin-right: 0;
        margin-bottom: 42px;
        text-align: center;
      }

      @include respond-to(phone) {
        font-size: 24px;
        line-height: 28px;
        margin-bottom: 30px;
      }

    }

    &__form{
      width: 100%;
      max-width: 580px;

      &__inner{
        padding: 12px 12px 12px 32px;
        border-radius: 42px;
        background-color: $white-grey;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include respond-to(mini) {
          padding: 10px 10px 10px 20px;
        }

        &__input{
          background: none;
          border: none;
          outline: none;
          color: $black;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          width: 100%;

          @include respond-to(mini) {
            font-size: 14px;
            line-height: 18px;
          }

          &::placeholder {
            color: rgba(0, 21, 24, 0.50);
          }

        }

        &__button{
          margin-left: 20px;
          @include main-button;

          @include respond-to(mini){
            min-width: 136px;
          }
        }

      }
    }
  }
}