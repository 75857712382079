.er_schedule__inner__mobile {
  display: none;

  @include respond-to(tablet) {
    display: block;
    position: relative;
  }

  &__item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: grab;

    &__img {
    }

    &__title {
      font-weight: 700;
      font-size: 24px;
      line-height: 30px;
      color: $black;
      text-align: center;

      @include respond-to(tablet) {
        margin-bottom: 40px;
      }

      @include respond-to(phone) {
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 20px;
      }
    }
  }

  &__arrows {
    &__prev,
    &__next {
      position: absolute;
      top: 50%;
      cursor: pointer;
      color: $black;
      z-index: 10;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 36px;
      height: 36px;
      margin-top: -18px;
      transition: opacity 0.25s linear;

      svg {
        width: 36px;
        height: 36px;
      }

      &:hover {
        opacity: 0.6;
      }

      &.swiper-button-disabled {
        color: $pagination;
        cursor: auto;
        pointer-events: none;
      }
    }

    &__prev {
      left: 0;
    }

    &__next {
      transform: rotate(180deg);
      right: 0;
    }
  }

  &__pagination {
    @include pagination;
  }
}
