* {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  font-style: normal;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  position: relative;
  overflow-x: hidden;
  margin-right: calc(-1 * (100vw - 100%));

  &.disable {
    @include respond-to(menu) {
      overflow: hidden;
    }
  }
}

.er,
footer {
  overflow: hidden;
}

.l-column {
  width: 100%;
  padding: 0 90px;

  @include respond-to(desktop-small) {
    padding: 0 50px;
    padding-right: calc(-1 * (100vw - 100%) + 50px);
  }

  @include respond-to(tablet-large) {
    padding: 0 32px;
    padding-right: calc(-1 * (100vw - 100%) + 32px);
  }
  @include respond-to(tablet) {
    padding: 0 30px;
    padding-right: calc(-1 * (100vw - 100%) + 30px);
  }

  @include respond-to(phone) {
    padding: 0 24px;
  }
}

.s-column {
  margin: 0 auto;
  width: 1160px;

  @include respond-to(tablet-large) {
    width: 920px;
  }

  @include respond-to(tablet) {
    width: 93.75vw;
  }

  @include respond-to(phone) {
    width: 87.5vw;
  }
}

a {
  text-decoration: none;
}

img {
  height: auto;
}