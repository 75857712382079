.er_brief {
  @include background-image('brief/bg', true);
  width: 100%;
  background-size: cover;
  margin-top: 120px;
  padding: 42px 0;

  @include respond-to(tablet) {
    margin-top: 80px;
    padding: 32px 0;
  }

  @include respond-to(phone) {
    margin-top: 60px;
    padding: 20px 0;
  }

  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include respond-to(phone) {
      flex-direction: column;
      justify-content: center;
    }

    &__title {
      font-weight: 700;
      font-size: 22px;
      line-height: 32px;
      color: $white-100;
      margin-right: 65px;

      @include respond-to(tablet) {
        font-size: 20px;
        line-height: 30px;
        margin-right: 45px;
      }

      @include respond-to(phone) {
        text-align: center;
        margin-right: 0;
        margin-bottom: 30px;
      }
    }

    &__button {
      @include main-button;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      position: relative;
      
      &-wrap {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        opacity: 0;
      }

      picture {
        margin-left: 10px;
      }
    }
  }
}
