.er_reviews {
  position: relative;

  &__overlay {
    position: relative;
  }

  &_top {
    padding-top: 60px;

    @include respond-to(tablet) {
      padding-top: 40px;
    }

    @include respond-to(phone) {
      padding-top: 30px;
    }
  }

  &_container {
    margin-top: -48px;

    @include respond-to(tablet) {
      margin-top: -24px;
    }
  }

  &__img {
    margin-bottom: 10px;
    img {
      margin: 0 auto;

      @include respond-to(phone) {
        width: 30px;
        height: 30px;
        object-fit: contain;
        margin-bottom: 8px;
      }
    }
  }

  &__title {
    @include h2;
    margin-bottom: 20px;

    @include respond-to(tablet) {
      margin-bottom: 10px;
    }

    @include respond-to(phone) {
      margin-bottom: 8px;
    }
  }

  &__text {
    @include h2_desc;
    margin-bottom: 104px;

    @include respond-to(tablet) {
      margin-bottom: 80px;
    }

    @include respond-to(phone) {
      margin-bottom: 60px;
    }
  }

  &__inner {
    position: relative;
    background-color: $white-grey;
    border-radius: 48px;
    display: flex;

    &__item {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 100px 56px;

      @include respond-to(tablet) {
        padding: 0 60px 34px;
      }

      @include respond-to(phone) {
        padding: 0 30px 22px;
      }

      &__img {
        width: 120px;
        height: 120px;
        border-radius: 50%;
        overflow: hidden;

        @include respond-to(tablet) {
          width: 80px;
          height: 80px;
        }
      }

      &__title {
        font-weight: 700;
        font-size: 28px;
        line-height: 34px;
        color: $black;
        margin-top: 40px;
        margin-bottom: 12px;
        text-align: center;

        @include respond-to(tablet) {
          font-size: 24px;
          line-height: 30px;
          margin-bottom: 10px;
        }

        @include respond-to(phone) {
          font-size: 20px;
          line-height: 24px;
          margin-bottom: 8px;
        }
      }

      &__post {
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        color: $grey;
        text-align: center;
        margin-bottom: 52px;

        @include respond-to(tablet) {
          font-size: 16px;
          line-height: 22px;
          margin-bottom: 36px;
        }

        @include respond-to(phone) {
          font-size: 14px;
          line-height: 20px;
          margin-bottom: 20px;
        }
      }

      &__text {
        font-weight: 500;
        font-size: 22px;
        line-height: 32px;
        color: $black;
        text-align: center;
        margin: auto;

        @include respond-to(tablet) {
          font-size: 20px;
          line-height: 30px;
        }

        @include respond-to(phone) {
          font-size: 18px;
          line-height: 26px;
        }

        @include respond-to(mini) {
          font-size: 16px;
          line-height: 24px;
        }
      }
    }

    &__pagination {
      @include pagination;
    }

    &__prev,
    &__next {
      position: absolute;
      top: 50%;
      cursor: pointer;
      color: $black;
      z-index: 10;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 44px;
      height: 44px;
      margin-top: -22px;
      transition: opacity 0.25s linear;

      @include respond-to(tablet) {
        display: none;
      }

      &:hover {
        opacity: 0.6;
      }

      &.swiper-button-disabled {
        color: $pagination;
        cursor: auto;
        pointer-events: none;
      }
    }

    &__prev {
      left: -84px;

      @include respond-to(desktop-small) {
        left: -60px;
      }

      @include respond-to(tablet-large) {
        left: -50px;
      }

      @include respond-to(tablet) {
        left: -45px;
      }

      @include respond-to(phone) {
        left: -25px;
      }
    }

    &__next {
      transform: rotate(180deg);
      right: -84px;

      @include respond-to(desktop-small) {
        right: -60px;
      }

      @include respond-to(tablet-large) {
        right: -50px;
      }

      @include respond-to(tablet) {
        right: -45px;
      }

      @include respond-to(phone) {
        right: -25px;
      }
    }

    &__arrows {
      display: none;
      @include respond-to(tablet) {
        display: flex;
      }

      .er_reviews_bottom & {
        position: absolute;
        width: 100vw;
        left: -3.125vw;
        height: 100%;
        top: 0;

        @include respond-to(phone) {
          left: -6.25vw;
        }
      }

      &__prev,
      &__next {
        position: absolute;
        top: 50%;
        cursor: pointer;
        color: $black;
        z-index: 10;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 44px;
        height: 44px;
        margin-top: -22px;
        transition: opacity 0.25s linear;

        @include respond-to(phone) {
          width: 36px;
          height: 36px;
          margin-top: -18px;

          svg {
            width: 36px;
            height: 36px;
          }
        }

        &:hover {
          opacity: 0.6;
        }

        &.swiper-button-disabled {
          color: $pagination;
          cursor: auto;
          pointer-events: none;
        }
      }

      &__prev {
        left: 8px;
      }

      &__next {
        transform: rotate(180deg);
        right: 8px;
      }
    }
  }
}
