@mixin background-image($img, $jpg-image: false, $retina: true) {
  background-repeat: no-repeat;

  @if $jpg-image == false {
    background-image: url(./assets/#{$img}.png);

    .webp & {
      background-image: url(./assets/#{$img}.webp);
    }

    @if $retina == true {
      @include media-retina {
        background-image: url(./assets/#{$img}@2x.png);

        .webp & {
          background-image: url(./assets/#{$img}@2x.webp);
        }
      }
    }
  } @else {
    background-image: url(./assets/#{$img}.jpg);

    .webp & {
      background-image: url(./assets/#{$img}.webp);
    }

    @if $retina == true {
      @include media-retina {
        background-image: url(./assets/#{$img}@2x.jpg);

        .webp & {
          background-image: url(./assets/#{$img}@2x.webp);
        }
      }
    }
  }
}

@mixin mockup-no-shadow {
  position: absolute;
  filter: drop-shadow(0px 12px 24px rgba(0, 0, 0, 0.2));
  width: 60%;
  opacity: 0;
  transition: opacity linear 250ms;
  &--front {
    left: 0;
    bottom: 0;
    z-index: 3;
  }
  &--back {
    right: 0;
    top: 0;
    z-index: 1;
  }
  &.active {
    opacity: 1;
  }
}

@mixin screen-in-mockup {
  position: absolute;
  width: 51%;
  opacity: 0;
  &--front {
    left: 4.3%;
    bottom: 2.6%;
    z-index: 4;
  }
  &--back {
    right: 4.5%;
    top: 2.5%;
    z-index: 2;
  }
  &.active {
    opacity: 1;
  }
}

@mixin card_after_scale($scale) {
  $length: 30px;
  width: $length * $scale;
  height: $length * $scale;
  border-radius: $length * $scale;
  right: -($length * math.div($scale, 2));
  bottom: -($length * math.div($scale, 2));
}
