.header {
  position: fixed;
  top: 0;
  z-index: 100;
  width: 100%;
  padding: 24px 0;
  transition: padding 0.55s ease-out;

  &.fixed, .er_header_fixed & {
    padding: 14px 0;
    background: rgba(236, 241, 247, 0.8);
    backdrop-filter: saturate(180%) blur(20px);
  }

  @include respond-to(menu) {
    padding: 14px 0;
  }

  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__logo_menu {
      display: flex;

      @include respond-to(menu) {
        flex-grow: 1;
        justify-content: space-between;
      }
    }

    &__links {
      display: flex;
      align-items: center;
    }
  }

  &__logo {
    color: $white-100;
    transition: color 0.55s ease-out, opacity 0.25s ease-out;

    &:hover{
      opacity: 0.6;
    }

    .fixed &, .er_header_fixed & {
      color: $black;
    }

    .open & {
      @include respond-to(menu) {
        color: $black;
        position: relative;
        z-index: 10;
      }
    }

    @include respond-to(menu) {
      width: 84px;
      height: 42px;
    }

    svg {
      @include respond-to(menu) {
        width: 84px;
        height: 42px;
      }
    }
  }

  &__menu {
    margin-left: 40px;

    @include respond-to(desktop-small) {
      margin-left: 20px;
      margin-right: 20px;
    }

    @include respond-to(tablet-large) {
      margin-right: 14px;
    }

    @include respond-to(menu) {
      display: none;
    }

    &__list {
      display: flex;
      &__item {
        display: flex;
        align-items: center;
        position: relative;
        padding: 14px 0;

        & > a {
          font-size: 14px;
          font-weight: 600;
          line-height: 17px;
          color: $white-100;
          text-decoration: none;
          transition: color 0.55s ease-out, opacity 0.25s ease-out;

          span{
            font-size: 14px;
            font-weight: 600;
          }

          .fixed &, .er_header_fixed & {
            color: $black;
          }
        }

        &:hover {
          & > a {
            opacity: 0.6;
          }

          .header__menu__list__item__icon {
            opacity: 0.6;
          }
        }

        &:not(:last-child) {
          margin-right: 28px;

          @include respond-to(tablet-large) {
            margin-right: 14px;
          }
        }

        &__icon {
          position: relative;
          margin-left: 3px;
          top: 1px;
          color: $white-100;
          transition: transform 0.55s ease-out, color 0.55s ease-out, opacity 0.55s ease-out;

          .fixed &, .er_header_fixed & {
            color: $black;
          }

          &_active {
            top: -1px;
            transform: rotate(180deg);
          }
        }

        &__sub {
          list-style-type: none;
          overflow: hidden;
          opacity: 0;
          pointer-events: none;
          transition: overflow 0.55s ease-in, opacity 0.25s ease-in;
          position: absolute;
          top: 50px;
          padding: 32px;
          min-width: 244px;
          border-radius: 20px;
          transform: translateX(-40px);
          background-color: $submenu;

          li {
            &:not(:last-child) {
              margin-bottom: 20px;
            }

            a {
              font-weight: 600;
              font-size: 14px;
              line-height: 20px;
              color: $white-100;
              transition: opacity 0.25s ease-out;
              display: block;

              &:hover {
                opacity: 0.6;
              }
            }
          }

          &_active {
            pointer-events: auto;
            overflow: visible;
            opacity: 1;
          }
        }
      }
    }
  }

  &__switch_lang {
    flex-shrink: 0;
    padding: 6px 9px 6px 7px;
    border: 1px solid $white-30;
    background-color: transparent;
    box-sizing: border-box;
    border-radius: 100px;
    text-decoration: none;
    display: flex;
    transition: background-color 0.55s linear;

    span {
      color: $white-100;
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      margin-right: 5px;
      transition: color 0.55s linear;
    }

    .fixed &, .er_header_fixed & {
      transition: color 0.55s ease-out, border 0.55s ease-out;
      border: 1px solid $black-30;
      span {
        color: $black;
      }
    }

    .open & {
      @include respond-to(menu) {
        position: relative;
        z-index: 10;
        transition: color 0.55s ease-out, border 0.55s ease-out;
        border: 1px solid $black-30;
        span {
          color: $black;
        }
      }
    }

    &:hover {
      background-color: $white-100;
      span {
        color: $black;
      }
      transition: background-color 0.55s ease-out, color 0.55s ease-out;

      .fixed &, .er_header_fixed & {
        background-color: $black;
        span {
          color: $white-100;
        }
      }

      .open & {
        @include respond-to(menu) {
          background-color: $black;
          span {
            color: $white-100;
          }
        }
      }
    }
  }

  &__phone {
    flex-shrink: 0;
    margin-left: 20px;
    margin-right: 48px;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: $white-100;
    transition: color 0.55s linear, opacity 0.25s linear;

    @include respond-to(menu) {
      display: none;
    }

    &:hover {
      opacity: 0.6;
    }

    .fixed &, .er_header_fixed & {
      transition: color 0.55s ease-out, opacity 0.25s linear;
      color: $black;

      &:hover {
        opacity: 0.6;
      }
    }

    @include respond-to(tablet-large) {
      margin-left: 10px;
      margin-right: 24px;
    }
  }

  &__price_button {
    &__mobile {
      display: none;
    }

    &.marquiz__container {
      width: auto;
    }

    .marquiz__button {
      @include main-button;
      padding-left: 44px;
      padding-right: 44px;
      min-width: auto;
      flex-shrink: 0;
      margin-right: 16px;
      width: auto;
      text-align: center;

      &:after {
        display: none;
      }
    }


    @include respond-to(tablet-large) {
      margin-right: 10px;
    }

    @include respond-to(menu) {
      display: none;

      &__mobile {
        display: inline-flex;

        &.marquiz__container {
          margin-right: 0;
        }

        .marquiz__button {
          margin-right: 0;
        }
      }
  
    }
  }

  &__auth_button {
    @include transparent-button;
    flex-shrink: 0;

    @include respond-to(menu) {
      display: none;
    }
  }

  &__mobile_btn {
    display: none;
    margin-left: 20px;
    padding: 5px;
    cursor: pointer;
    position: relative;
    z-index: 10;

    @include respond-to(menu) {
      display: block;
    }

    span {
      position: relative;
      display: block;
      width: 20px;
      height: 2px;
      background: $white-100;
      border-radius: 3px;
      z-index: 1;
      transform-origin: 0 0;
      transition: transform 0.55s cubic-bezier(0.77, 0.2, 0.05, 1), background 0.55s cubic-bezier(0.77, 0.2, 0.05, 1),
        opacity 0.55s ease;

      .fixed &, .er_header_fixed & {
        background: $black;
      }

      &:not(:last-child) {
        margin-bottom: 4px;
      }

      &:last-child {
        transform-origin: 0 100%;
      }
    }

    .open & {
      span {
        opacity: 1;
        transform: rotate(45deg) translate(2px, -3px);
        background: $black;
      }

      span:nth-child(2) {
        opacity: 0;
        transform: rotate(0deg) scale(0.2, 0.2);
      }

      span:last-child {
        transform: rotate(-45deg) translate(2px, 3px);
      }
    }
  }

  &__mobile {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    padding: 70px 24px 36px;

    &::before {
      content: '';
      top: 0;
      right: 0;
      background-color: $white-grey;
      position: absolute;
      z-index: 1;
      width: 20px;
      height: 20px;
      transition: all 0.55s ease;
      opacity: 0;
      border-radius: 12px;
    }

    @include respond-to(menu) {
      display: block;
      opacity: 0;
      visibility: hidden;
      transition: 0.3s opacity linear, 0.3s visibility linear;
    }

    .open & {
      @include respond-to(menu) {
        opacity: 1;
        visibility: visible;
        transition: 0.25s opacity linear, 0.25s visibility linear;

        &::before {
          top: 50%;
          right: 50%;
          width: 300vh;
          height: 300vh;
          margin: -150vh -150vh 0 0;
          opacity: 1;
          border-radius: 150vh;
        }
      }
    }

    &__inner {
      overflow-y: auto;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      &__menu {
        margin-top: 24px;
        position: relative;
        z-index: 1;
        width: 100%;

        &__list {
          list-style-type: none;
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;

          &__item {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;

            &:not(:last-child) {
              margin-bottom: 36px;
              transition: margin-bottom 0.55s ease-in;
            }

            &_nest:not(:last-child) {
              margin-bottom: 26px;
              transition: margin-bottom 0.55s ease-out;
            }

            & > a {
              display: flex;
              align-items: center;
              font-weight: 600;
              font-size: 16px;
              line-height: 22px;
              color: $black;
              margin-bottom: 0;
              transition: opacity 0.55s linear, color 0.55s linear, margin-bottom 0.55s ease-in;

              svg {
                position: relative;
                top: 2px;
                margin-left: 6px;
                transition: transform 0.55s ease-out, color 0.55s ease-out, opacity 0.55s ease-out;
              }

              &.active {
                color: $pink;
                margin-bottom: 26px;
                transition: color 0.55s linear, margin-bottom 0.55s ease-out;

                a {
                  margin-bottom: 26px;
                }

                svg {
                  transform: rotate(180deg);
                }
              }
            }

            &__sub {
              width: 100%;
              max-width: 312px;
              padding: 0 26px;
              background-color: $white-100;
              border-radius: 20px;
              list-style-type: none;
              text-align: center;
              overflow: hidden;
              position: relative;
              max-height: 0;
              transition: max-height 0.55s ease-out;

              &_active {
                max-height: 1000px;
                transition: max-height 0.55s ease-in;
              }

              li {
                width: 100%;

                &:first-child {
                  margin-top: 26px;
                }

                &:last-child {
                  margin-bottom: 26px;
                }

                &:not(:last-child) {
                  margin-bottom: 38px;
                }

                a {
                  font-weight: 600;
                  font-size: 14px;
                  line-height: 20px;
                  color: $black;
                  opacity: 0.6;
                }
              }
            }
          }
        }
      }

      &__phone {
        position: relative;
        z-index: 1;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: $black;
        margin-top: 92px;
      }

      &__price_button {
        position: relative;
        z-index: 1;
        @include main-button;
        min-width: 200px;
        margin-top: 40px;
      }

      &__auth_button {
        position: relative;
        z-index: 1;
        margin-top: 16px;
        @include transparent-button;
        min-width: 200px;
      }
    }
  }
}
