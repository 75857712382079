$white-100: rgba(255, 255, 255, 1);
$white-85: rgba(255, 255, 255, 0.85);
$white-80: rgba(255, 255, 255, 0.8);
$white-60: rgba(255, 255, 255, 0.6);
$white-50: rgba(255, 255, 255, 0.5);
$white-30: rgba(255, 255, 255, 0.3);
$white-12: rgba(255, 255, 255, 0.12);
$white-10: rgba(255, 255, 255, 0.1);

$white-grey: #f3f5f9;
$black: #151836;
$black-60: rgba(0, 0, 0, 0.6);
$black-30: rgba(21, 24, 54, 0.3);
$background-black: #1d232a;
$dark: #1d232a;
$submenu: #000817;
$grey: #696c74;
$pagination: #dadde6;
$blue: #0720f7;
$lightBlue: #1e4bf5;
$border: #c6c8cf;
$light-grey: #b4b8c1;
$pink: #ff29ae;
$pink-hover: #cd27bd;
$input-light: rgba(146, 146, 146, 0.1);
$input-error: rgba(255, 0, 77, 0.2);
$border-error: #ff004d;
$shadow: rgba(25, 46, 84, 0.1);
$title-black: #001518;
