@keyframes fade-in {
  0% {
    visibility: hidden;
    opacity: 0;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}

.er_form {
  padding: 80px 0;
  background-image: radial-gradient(126.13% 100% at 50% 0%, #644bff 0%, #0720f7 100%);
  user-select: none;

  @include respond-to(tablet) {
    padding: 60px 0;
  }

  @include respond-to(phone) {
    padding: 40px 0;
  }

  &__inner {
    width: 100%;
    max-width: 800px;
    padding: 0 20px;
    margin: 0 auto;
  }
}

.er_form__block {
  position: relative;

  &__modal {
    width: 100%;
  }

  &__final {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    color: $white-100;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease-in, transform 0.5s ease-in-out;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    transform: scale(0.8);

    .form-modal & {
      color: $black;
    }

    img {
      width: 108px;
      margin-bottom: 48px;

      @include respond-to(tablet) {
        width: 92px;
        margin-bottom: 24px;
      }

      @include respond-to(phone) {
        width: 80px;
        margin-bottom: 24px;
      }
    }

    &__title {
      font-weight: 800;
      font-size: 32px;
      line-height: 40px;
      margin-bottom: 20px;

      @include respond-to(tablet) {
        font-size: 24px;
        line-height: 30px;
        margin-bottom: 16px;
      }

      @include respond-to(phone) {
        font-size: 20px;
        line-height: 25px;
      }
    }

    &__text {
      text-align: center;
      font-weight: 500;
      font-size: 22px;
      line-height: 32px;
      color: $white-50;

      @include respond-to(tablet) {
        font-size: 20px;
        line-height: 30px;
      }

      @include respond-to(phone) {
        font-size: 18px;
        line-height: 28px;
      }

      .form-modal & {
        color: $black;
        opacity: 0.5;
      }
    }
  }

  &.success {
    .er_form__block__final {
      opacity: 1;
      visibility: visible;
      transform: scale(1);
    }

    .er_form__block__container {
      visibility: hidden;
      opacity: 0;
      transform: scale(0.8);
    }
  }

  &__container {
    transition: opacity 1s ease-out, transform 1s ease-in-out;
    opacity: 1;
    transform: scale(1);
    text-align: center;

    &__title {
      @include h2;
      color: $white-100;
      margin-bottom: 60px;

      @include respond-to(tablet) {
        margin-bottom: 40px;
      }

      @include respond-to(phone) {
        margin-bottom: 30px;
      }

      .form-modal & {
        color: $black;
      }

      &_footer{
        margin-bottom: 20px;
      }
    }

    &__text{
      margin-bottom: 40px;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: $white-50;
      max-width: 560px;
      width: 100%;
      margin-left: auto;
      margin-right: auto;

      @include respond-to(phone) {
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 20px;
      }

      a {
        color: $white-100;
        transition: color 0.25s linear, opacity 0.25s linear;

        .form-modal & {
          color: $black;
        }

        &:hover {
          color: $white-80;

          .form-modal & {
            color: $black;
            opacity: 0.8;
          }
        }
      }
    }

    &__fields {
      &_line {
        display: flex;
        margin-top: 20px;

        @include respond-to(phone) {
          flex-direction: column;
          margin-top: 16px;
        }

        & > div {
          &:not(:last-child) {
            margin-right: 40px;

            @include respond-to(phone) {
              margin-right: 0;
              margin-bottom: 16px;
            }
          }
        }
      }

      &__input {
        background-color: $white-10;
        border-radius: 12px;
        border: 1px solid transparent;
        width: 100%;
        height: 56px;

        .form-modal & {
          background-color: $input-light;
        }

        &:focus-within {
          border-color: $white-100;

          .form-modal & {
            border-color: $blue;
          }
        }

        &.error {
          background-color: $input-error;
          border-color: $white-100;
          position: relative;
          display: flex;
          align-items: center;

          .form-modal & {
            border-color: #ff004d;
          }

          &::after {
            content: url('./assets/form/ic24_alert.svg');
            position: absolute;
            right: 16px;
            height: 24px;
            width: 24px;

            .form-modal & {
              content: url('./assets/form/ic24_alert_red.svg');
            }
          }

          &:hover .er_form__block__container__fields__input__error {
            opacity: 1;
            visibility: visible;
          }

          .er_form__block__container__fields__input__error {
            animation-name: fade-in;
            animation-iteration-count: 2;
            animation-direction: alternate;
            animation-duration: 1s;
            display: block;
          }
        }

        input {
          background-color: transparent;
          padding: 0 16px;
          width: 100%;
          height: 100%;
          border: none;
          color: $white-100;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;

          .form-modal & {
            color: $black;
          }

          &::placeholder {
            color: $white-50;
            font-weight: 400;

            .form-modal & {
              color: $black;
              opacity: 0.5;
            }
          }

          &:focus {
            outline: none;
          }
        }

        &__error {
          visibility: hidden;
          opacity: 0;
          position: absolute;
          background-color: $white-100;
          border-radius: 10px;
          border: 1px solid #e0e0e0;
          padding: 12px 20px;
          right: 0;
          top: 70px;
          transition: opacity 0.3s ease, visibility 0.3s ease;
          z-index: 10;
          box-shadow: 0 4px 20px rgb(0 0 0 / 10%);
          font-size: 14px;
          font-weight: 600;

          &::after {
            display: block;
            position: absolute;
            top: -10px;
            right: 10px;
            content: '';
            width: 0;
            height: 0;
            border-left: 18px solid transparent;
            border-right: 18px solid transparent;
            border-bottom: 18px solid $white-100;
          }
        }
      }

      &__textarea {
        background-color: $white-10;
        border-radius: 12px;
        border: 1px solid transparent;
        width: 100%;
        height: 104px;
        margin-top: 20px;

        @include respond-to(phone) {
          margin-top: 16px;
        }

        .form-modal & {
          background-color: $input-light;
        }

        &:focus-within {
          border-color: #fff;

          .form-modal & {
            border-color: $blue;
          }
        }

        textarea {
          background-color: transparent;
          padding: 16px;
          width: 100%;
          height: 100%;
          border: none;
          color: $white-100;
          resize: none;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;

          .form-modal & {
            color: $black;
          }

          &::placeholder {
            color: $white-50;
            font-weight: 400;

            .form-modal & {
              color: $black;
              opacity: 0.5;
            }
          }

          &:focus {
            outline: none;
          }
        }
      }
    }

    &__button {
      margin-top: 48px;
      display: flex;
      justify-content: center;
      align-items: center;

      @include respond-to(tablet) {
        margin-top: 32px;
      }

      @include respond-to(phone) {
        margin-top: 22px;
      }

      button {
        width: 100%;
        max-width: 282px;

        .er_form & {
          @include white-button;
        }

        .form-modal & {
          @include main-button;
        }
      }
    }

    &__policy {
      margin: 20px auto 0;
      max-width: 560px;
      width: 100%;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: $white-50;

      @include respond-to(phone) {
        margin: 16px auto 0;
        font-size: 14px;
        line-height: 22px;
      }

      .form-modal & {
        color: $black;
        opacity: 0.8;
      }

      a {
        color: $white-100;
        transition: color 0.25s linear, opacity 0.25s linear;

        .form-modal & {
          color: $black;
        }

        &:hover {
          color: $white-80;

          .form-modal & {
            color: $black;
            opacity: 0.8;
          }
        }
      }
    }
  }
}
